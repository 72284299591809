// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyBy_wnzktsdklsLNfqPP5I2orqzfiz6uaQ",
  authDomain: "yvomnew2nd.firebaseapp.com",
  databaseURL: "https://yvomnew2nd-default-rtdb.firebaseio.com",
  projectId: "yvomnew2nd",
  storageBucket: "yvomnew2nd.appspot.com",
  messagingSenderId: "334763310428",
  appId: "1:334763310428:web:08768f5786867c7e545599",
  measurementId: "G-6FX1EEJMTK"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };